import { createContext } from 'react';
import { ClusterUser } from '../../models/ClusterUser';

export interface IAuthContext {
  loading: boolean;
  customToken: string | undefined;
  clusterUsers: ClusterUser[] | [];
  currentClusterUser: ClusterUser | undefined;
  error: string | undefined;
  login: (email: string, password: string) => Promise<void>;
  loginWithToken: (token: string) => Promise<void>;
  loginWithOAuth: (providerId: string, informPartnerUser: boolean, email?: string) => Promise<void>;
  logout: () => Promise<void>;
  getToken: (source?: string) => Promise<string | null>;
}

export const AuthContext = createContext<IAuthContext>({
  loading: false,
  error: undefined,
  customToken: undefined,
  clusterUsers: [],
  currentClusterUser: undefined,
  // eslint-disable-next-line
  login: (email: string, password: string): Promise<void> => {
    throw new Error('login(email: string, password: string): Promise<void> not implemented');
  },
  // eslint-disable-next-line
  loginWithToken: (token: string): Promise<void> => {
    throw new Error('loginWithToken(token: string): Promise<void> not implemented');
  },
  // eslint-disable-next-line
  loginWithOAuth: (providerId: string, informPartnerUser: boolean, email?: string): Promise<void> => {
    throw new Error(
      'loginWithOAuth(providerId: string, informPartnerUser: boolean, email?: string) => Promise<void> not implemented',
    );
  },
  // eslint-disable-next-line
  logout: (): Promise<void> => {
    throw new Error('logout(): void not implemented');
  },
  // eslint-disable-next-line
  getToken: (): Promise<string | null> => {
    throw new Error('getToken(): Promise<void> not implemented');
  },
});
