import type { AppProps } from 'next/app';
import Wrapper from '../shared/components/Wrapper';
import AuthProvider from '../shared/providers/auth/authProvider';
import Script from 'next/script';
import Head from 'next/head';
import { KibbleThemeProvider } from '@televet/kibble-ui/build/theme';
import CustomWrapper from '../shared/components/CustomWrapper';
import { useMemo } from 'react';
import { OAuthProviderIds } from '../shared/models/OAuthProviderIds';
import { getCustomWrapperStyles } from '../shared/utils/styles';
import { useRouter } from 'next/router';

const CUSTOM_FIREBASE_PROVIDERS = [OAuthProviderIds.BI, OAuthProviderIds.EntraTest];

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  const providerId = useMemo(() => router.query.providerId, [router]);

  const useCustomWrapper = useMemo(() => {
    // if router isn't ready, return undefined so we don't render either wrapper
    // otherwise we end up with flash of standard wrapper before rendering custom wrapper
    if (!router.isReady) {
      return;
    }

    if (typeof providerId === 'string' && (CUSTOM_FIREBASE_PROVIDERS as string[]).includes(providerId)) {
      return true;
    }

    return false;
  }, [providerId, router]);

  const customWrapperStyles = useMemo(() => {
    if (typeof providerId === 'string') {
      return getCustomWrapperStyles(providerId);
    }
  }, [providerId]);

  return (
    <>
      <Script src="/env.js" strategy="beforeInteractive" />
      <KibbleThemeProvider>
        <AuthProvider>
          <Head>
            <title>Otto Login</title>
            <meta name="description" content="Log in to Otto Flow" />
            <meta name="robots" content="noindex" />
            <link rel="icon" href="/favicon/favicon.ico" />
          </Head>
          {useCustomWrapper === true && (
            <CustomWrapper customStyles={customWrapperStyles}>
              <Component {...pageProps} />
            </CustomWrapper>
          )}
          {useCustomWrapper === false && (
            <Wrapper>
              <Component {...pageProps} />
            </Wrapper>
          )}
        </AuthProvider>
      </KibbleThemeProvider>
    </>
  );
}

export default MyApp;
