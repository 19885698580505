import * as Sentry from '@sentry/nextjs';

export const initSentry = (): void => {
  Sentry.init({
    dsn: 'https://1e8db1fcf63540bd82fd67c82bdbf64c@o358860.ingest.sentry.io/6585105',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
};
