import { getApps, initializeApp } from 'firebase/app';
import { env } from './env';
import {
  User,
  UserCredential,
  setPersistence,
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  browserLocalPersistence,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';
import { LocalStorageKeys } from '../models/LocalStorageKeys';
import { createHashFromString } from './createHash';

export const firebaseLogin = async (email: string, password: string): Promise<UserCredential> => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const firebaseLoginWithCustomToken = async (token: string): Promise<UserCredential> => {
  await setPersistence(auth, browserLocalPersistence);
  return await signInWithCustomToken(auth, token);
};

export const firebaseLoginWithOAuth = async (providerId: string, email?: string): Promise<void | User | null> => {
  const emailHash = createHashFromString(email);
  await auth.authStateReady();
  const firebaseUser = await getFirebaseUser();
  const currentUserProviderId = firebaseUser?.providerData?.[0]?.providerId;

  // Firebase doesn't have or return the user's email for SSO users, so we can't compare that to the one in the URL
  // use localstorage to compare the new email to the previously authenticated user's to see if it's the same user
  const lastSSOEmail = localStorage.getItem(LocalStorageKeys.LastOttoSSOEmail);

  // if we already have an active Firebase session for this user and provider, no need to recheck SSO
  // skip if provider is different, email is different, or email isn't provided
  if (currentUserProviderId === providerId && lastSSOEmail === emailHash && email) {
    return firebaseUser;
  }

  // check if we're coming back from a sign-in redirect first to avoid looping
  // if we dont't get valid credentials back, then redirect to SSO sign-in page
  const userCredential = await getRedirectResult(auth)
    .then((result) => {
      localStorage.setItem(LocalStorageKeys.LastOttoSSOEmail, emailHash || '');
      return result;
    })
    .catch((error) => {
      console.error('Error getting redirect result', error);
    });

  if (userCredential) {
    return userCredential.user;
  } else {
    const provider = new OAuthProvider(providerId);

    // if email address is provided, send it as a login hint to go straight to password entry
    if (email) {
      provider.setCustomParameters({
        login_hint: email,
      });
    }

    signInWithRedirect(auth, provider);
  }
};

export const firebaseLogout = async (): Promise<void> => {
  return await signOut(auth);
};

export const getFirebaseUser = async (): Promise<User | null> => {
  // TODO: look into using onAuthStateReady here and removing the timeout below
  let currentUser = auth.currentUser;
  if (!currentUser) {
    await timeout(500);
    currentUser = auth.currentUser;
  }
  return currentUser;
};

export const getFirebaseToken = async (): Promise<string | undefined> => {
  const currentUser = await getFirebaseUser();
  if (currentUser) {
    return await currentUser.getIdToken(/* forceRefresh */ false);
  } else {
    return undefined;
  }
};

const timeout = (amount: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, amount);
  });

const apps = getApps();

const firebase = !apps.length
  ? initializeApp(
      {
        // GH build fails without fallback apiKey
        apiKey: env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY || '123',
        authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        databaseURL: env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
        projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
      },
      'login',
    )
  : apps[0];

export const auth = getAuth(firebase);

export default firebase;
