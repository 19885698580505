import { ReactNode } from 'react';
import { Box, Flex } from '@televet/kibble-ui/build/chakra';
import Image from 'next/image';

const Wrapper = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Box as="main" h="100vh" w="100%" bg="background.subtle">
      <Flex alignItems="center" justifyContent="center" height="100%" padding={{ base: 0, lg: 20 }}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          bg="background.default"
          border="1px"
          borderColor="border.default"
          width="100%"
          height="100%"
          gap={{ base: 10, lg: 0 }}
          borderRadius="8px"
        >
          <Box
            flexBasis={{ base: '100%', lg: '50%' }}
            height="100%"
            position="relative"
            borderTopLeftRadius={{ base: 0, lg: '8px' }}
            borderBottomLeftRadius={{ base: 0, lg: '8px' }}
            overflow="hidden"
          >
            <Image
              priority={true}
              objectFit="cover"
              objectPosition="50% 30%"
              src={'/assets/auth-page-splash-screen.png'}
              alt="Otto"
              layout="fill"
            />
          </Box>
          <Flex
            flexBasis={{ base: '100%', lg: '50%' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            justifyContent="center"
            overflowY="auto"
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Wrapper;
