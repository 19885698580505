import { useCallback, useEffect, useState } from 'react';
import { getFirebaseToken } from '../utils/firebase';

// eslint-disable-next-line
export function useFetch<T, BT = any>({
  url,
  fetchOptions,
  lazy = false,
}: {
  url: string;
  fetchOptions?: RequestInit;
  lazy?: boolean;
}): {
  data?: T;
  errors?: { status: number; message: string };
  fetch: (body?: BT | undefined, urlOverride?: string | undefined) => Promise<void>;
} {
  const [data, setData] = useState<T>();
  const [errors, setErrors] = useState<{ status: number; message: string }>();
  const runFetch = useCallback(
    async function (body?: BT, urlOverride?: string) {
      const token = await getFirebaseToken();
      const resp = await fetch(urlOverride || url, {
        ...fetchOptions,
        headers: {
          ...fetchOptions?.headers,
          authorization: token ? `Bearer ${token}` : '',
        },
        body: body ? JSON.stringify(body) : undefined,
      });
      if (resp.status === 200) {
        setData(await resp.json());
      } else {
        setErrors({ status: resp.status, message: resp.statusText });
      }
    },
    [url, fetchOptions],
  );
  useEffect(() => {
    if (!lazy) {
      runFetch();
    }
  }, [lazy, runFetch]);
  return { data, errors, fetch: runFetch };
}
