declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  NODE_ENV: 'development' | 'production' | 'test';
  NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY: string;
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: string;
  NEXT_PUBLIC_FIREBASE_DATABASE_URL: string;
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: string;
  NEXT_PUBLIC_FIREBASE_APP_ID: string;
};
let env: EnvType = { ...process.env } as EnvType;
if (typeof window !== 'undefined') {
  env = { ...process.env, ...window.env };
}
export { env };
