import { CSSProperties, ReactNode } from 'react';
import { Flex } from '@televet/kibble-ui/build/chakra';

const CustomWrapper = ({
  children,
  customStyles,
}: {
  children: ReactNode;
  customStyles?: CSSProperties;
}): JSX.Element => {
  return (
    <Flex as="main" h="100vh" w="100%" justifyContent="center" alignItems="center" style={customStyles}>
      {children}
    </Flex>
  );
};

export default CustomWrapper;
