import { CSSProperties } from 'react';
import { OAuthProviderIds } from '../models/OAuthProviderIds';

export const getCustomWrapperStyles = (providerId: string): CSSProperties | undefined => {
  switch (providerId) {
    case OAuthProviderIds.BI as string:
    case OAuthProviderIds.EntraTest as string:
      return {
        backgroundColor: '#08312A',
      };
    default:
      return;
  }
};

export const getCustomOAuthTextColor = (providerId: string): string | undefined => {
  switch (providerId) {
    case OAuthProviderIds.BI as string:
    case OAuthProviderIds.EntraTest as string:
      return '#FFFFFF';
    default:
      return;
  }
};

export const getCustomOAuthSpinnerColor = (providerId: string): string | undefined => {
  switch (providerId) {
    case OAuthProviderIds.BI as string:
    case OAuthProviderIds.EntraTest as string:
      return '#00E47C';
    default:
      return '#554EF6';
  }
};
